<template>
  <div class="task-values">
    <div v-if="milestone" class="milestone">
      <div class="form-row milestone-desc">
        <span>
          <b>Milestone:</b> <i>"{{ milestone.workflow.milestoneTitle }}"</i>
        </span>
        <span v-if="milestone.workflow.milestoneAssignees">
          , <b>assigned to:</b> <i>{{ milestone.workflow.milestoneAssignees.join(', ') }}</i>
        </span>
        <span v-if="milestoneDate">
          , <b>due at:</b> <i>{{ milestoneDate }}</i>
        </span>
      </div>
    </div>
    <div v-else class="milestone">
      <span>
        No milestone
      </span>
    </div>
    <div class="form-row">
      <p-text-field :model-value="data.task.title" :label="'Title'" autocomplete="off" :disabled="true" />
    </div>
    <div class="form-row">
      <hub-date :value="data.task.dueAt" label="Due At" :disabled="true" :event-subject="data.task.title" />
    </div>

    <div class="form-row">
      <p-assignees :value="data.task.assignees" label="Assigned to" placeholder="" :disabled="true" />
    </div>
    <div class="form-row">
      <FilePicker :value="attachments" label="Attachments" :reference="matterReference" :disabled="true" :multiple="true" />
    </div>
    <div class="form-row">
      <div class="header">
        Notes
      </div>
      <hub-editor v-model="notes" class="task-notes" :disabled="true" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';

import TextField from '@/components/common/TextField';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';
import FilePicker from '@/components/common/filepicker/FilePicker';
import Editor from '@/components/common/editor/Editor';

export default {
  components: {
    'p-text-field': TextField,
    'hub-editor': Editor,
    'p-assignees': Assignees,
    'hub-date': DateField,
    FilePicker
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    invention: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      details: s => s.reports.myInventions.details
    }),
    milestone() {
      if (!this.data.task.workflow) {
        return;
      }

      const task = this.data.task;

      const details = this.details[this.$route.params.id] || this.details[task.inventionId];
      const list = details?.milestones;

      return list?.find(m => m.workflow.milestoneId === task.workflow.milestoneId);
    },
    milestoneDate() {
      return (
        this.milestone &&
        this.milestone.workflow &&
        this.milestone.workflow.milestoneDueAt &&
        format(new Date(this.milestone.workflow.milestoneDueAt), 'MM/dd/yyyy')
      );
    },
    matterReference() {
      return this.data.invention?.references.find(ref => ref.toLowerCase().startsWith('harrity:'));
    },
    attachments() {
      return this.data.task.attachments || [];
    },
    notes() {
      return this.data.task.notes;
    }
  }
};
</script>
<style lang="scss" scoped>
.milestone {
  margin-bottom: 0.5rem;
}
.task-values {
  padding: 0.5rem 0 0.5rem;
  .task-notes {
    background: var(--theme-background);
    border: 1px solid var(--theme-on-background-accent);
  }

  .form-row {
    margin-bottom: 0.5rem;
    .header {
      font-weight: 500;
      font-size: 0.8rem;
      letter-spacing: 0.025em;
      margin-bottom: 5px;
    }
    &.milestone-desc {
      b {
        font-weight: 600;
      }
      i {
        font-style: italic;
      }
    }
  }
}
</style>
