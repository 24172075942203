<template>
  <hub-modal :visible="true" class="merge-task" closable @close="$emit('close')">
    <template #title>Resolve conflicts</template>
    <div class="merge-task-modal">
      <div class="merge-task-row">
        <span></span>
        <span>Your edits</span>
        <span>{{ conflicts.editor }} edits</span>
      </div>
      <div v-for="conflict in localConflicts" :key="conflict.field" class="merge-task-row">
        <div>{{ conflict.field }}</div>
        <div class="value">
          <input v-model="conflict.selected" type="radio" :name="conflict.field" value="your" />
          <hub-date v-if="conflict.field === 'dueAt'" v-model:value="conflict.your" />
          <p-assignees v-if="conflict.field === 'assignees'" v-model:value="conflict.your" />
          <p-text-field
            v-if="conflict.field === 'title' || conflict.field === 'notes'"
            v-model="conflict.your"
            :multiline="conflict.field === 'notes'"
          />
        </div>
        <div class="value">
          <input v-model="conflict.selected" type="radio" :name="conflict.field" value="their" />

          <hub-date v-if="conflict.field === 'dueAt'" :value="conflict.their" :disabled="true" />
          <p-assignees v-if="conflict.field === 'assignees'" :value="conflict.their" :disabled="true" />
          <p-text-field
            v-if="conflict.field === 'title' || conflict.field === 'notes'"
            v-model="conflict.their"
            :disabled="true"
            :multiline="conflict.field === 'notes'"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <p-button variant="text" @click="$emit('close')">Cancel</p-button>
        <p-button style="justify-self: start" type="button" color="primary" @click="resolve">Resolve</p-button>
      </div>
    </template>
  </hub-modal>
</template>
<script>
import Modal from './../../common/Modal';
import TextField from '@/components/common/TextField';

import Button from '@/components/common/Button';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';

export default {
  components: {
    'hub-modal': Modal,
    'p-text-field': TextField,
    'p-button': Button,
    'p-assignees': Assignees,
    'hub-date': DateField
  },
  props: {
    conflicts: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['close', 'submit'],
  data() {
    return {
      localConflicts: this.conflicts.conflicts.map(c => ({ ...c, selected: 'your' }))
    };
  },
  methods: {
    resolve() {
      const result = this.localConflicts.map(obj => ({
        field: obj.field,
        value: obj[obj.selected]
      }));
      this.$emit('submit', result);
    }
  }
};
</script>
<style lang="scss">
.merge-task {
  .modal {
    min-height: 90%;
    width: 80vw;
    grid-template-rows: max-content minmax(0, 1fr) max-content;
  }
}
</style>
<style lang="scss" scoped>
.merge-task {
  .modal {
    min-height: 90%;
    width: 1024px;
    grid-template-rows: max-content minmax(0, 1fr) max-content;
    .footer {
      padding: 0 15px;
    }
  }
}
.merge-task-modal {
  padding: 15px;

  .merge-task-row {
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 15px;
    align-items: center;
    .value {
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      grid-gap: 10px;
    }
  }
}
</style>
